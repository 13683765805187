import { gql } from "@apollo/client";

export const createPlanMutation = gql`
  mutation createPlan(
    $name: String!
    $description: String!
  ) {
    createPlan(
      name: $name
      description: $description
    ) {
      id
      name
      description
    }
  }
`;
