import React, { useCallback, useState } from "react";
import { Box, Typography, Card, Stack, Button } from "@mui/material";
import { EDIT_ACTION } from "./hooks/useForm";
import { useConfirm } from "material-ui-confirm";

export const PDForm = ({
  label,
  children,
  mode,
  readOnly,
  switchToEdit,
  switchToView,
  allowActions,
  form,
  formType = "edit",
}) => {
  const confirm = useConfirm();

  const resetForm = useCallback(() => {
    if (form?.dirty === false) {
      form.resetForm();
      switchToView();
      return;
    }

    confirm({ description: `Are you sure you want to overwrite your changes?` })
      .then(() => {
        form.resetForm();
        switchToView();
      })
      .catch(() => {});
  }, [switchToView, form, confirm]);

  return (
    <Box component="form" onSubmit={form?.handleSubmit} noValidate>
      <Stack orientation="column" alignItems="center">
        <Box sx={{ pt: 5 }}>
          <Box marginBottom={1}>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 700,
              }}
            >
              {label}
            </Typography>
          </Box>
          <Box sx={{ border: "solid 1px #000", p: 4 }}>{children}</Box>
          {allowActions && (
            <Stack
              spacing={2}
              direction="row"
              sx={{ p: 2, justifyContent: "space-between" }}
            >
              {formType !== "new" && mode !== EDIT_ACTION && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 0 }}
                  onClick={switchToEdit}
                >
                  Edit
                </Button>
              )}
              {mode === EDIT_ACTION && formType !== "new" && (
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ borderRadius: 0 }}
                  onClick={resetForm}
                >
                  Cancel
                </Button>
              )}
              {!readOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  type="submit"
                  sx={{ borderRadius: 0 }}
                >
                  Save
                </Button>
              )}
            </Stack>
          )}
        </Box>
      </Stack>
    </Box>
  );
};
