import { gql } from "@apollo/client"

export const getTenant = gql`query {
  getTenant {
    id
    tenantName
    name
    organizationType
    employeeCount
    industry
    market
    organizationPriorities
    organizationStory
    email
    address
  }
}`