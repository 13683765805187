import { SidebarLayout } from "src/layouts/SidebarLayout";
import { TenantRoutes } from "./TenantRoutes";
import { useAuthContext } from "src/hooks/useAuthContext";
import { Navigate } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";

export const TenantRoot = () => {
  const { isLoggedIn, isLoading } = useAuthContext();

  if (!isLoggedIn && !isLoading) {
    return <Navigate to="/login" />;
  }


  const client = new ApolloClient({
    uri: '/api/graphql',
    cache: new InMemoryCache(),
  });

  
  return (
    <ApolloProvider client={client}>
      <SidebarLayout>
        <TenantRoutes />
      </SidebarLayout>
    </ApolloProvider>
  );
};
