import { Box, SliderMarkLabel } from "@mui/material";

export const BasicMark = (props) => {
  const index = props["data-index"];
  const mark = props.ownerState.marks[index];

  return (
    <SliderMarkLabel {...props}>
        <Box component="span" sx={{ml: '50px'}}>{mark.label}</Box>
    </SliderMarkLabel>
  );
};