import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import {
  Checkbox,
  FormControlLabel,
  Box,
  Grid,
  Typography,
  Stack,
  Button,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { enter } from "../api/auth";
import { ErrorLabel } from "../common/forms/ErrorLabel";
import { PDForm } from "../components/PDForm";
import errorCodes from "@pbhapp/common/constants/errorCodes";
import * as SessionStatus from "@pbhapp/common/enums/sessionStatus";
import { PDTextField } from "../components/PDTextField";
import { useAuthContext } from "src/hooks/useAuthContext";
import { PDLoadingButton } from "src/components/PDLoadingButton";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
import { useTenantContext } from "src/hooks/useTenantContext";

const LoginSchema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Email required"),
  password: Yup.string().required("Password required"),
});

export const Login = () => {
  const { tenantId, isGlobalTenant, isTenantSpecificDomain } =
    useTenantContext();

  const { startUserSession, isLoggedIn, isSessionVerified } = useAuthContext();

  const [cookies, setCookie, removeCookie] = useCookies([
    "rememberMe",
    "email",
  ]);

  const [loading, setLoading] = useState(false);

  const { showErrorMessage } = useSnackbarUtils();

  const navigate = useNavigate();

  const { email, rememberMe } = cookies;

  const loginForm = useFormik({
    initialValues: {
      email: email ?? "",
      password: "",
      rememberMe: rememberMe ?? false,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const {
          success,
          message: errorCode,
          sessionStatus,
          user,
        } = await enter(values);

        if (success) {
          if (values.rememberMe) {
            setCookie("rememberMe", true);
            setCookie("email", values.email);
          } else {
            removeCookie("rememberMe");
            removeCookie("email");
          }

          startUserSession({
            sessionStatus,
            user,
          });

          if (sessionStatus === SessionStatus.NOT_VERIFIED) {
            navigate("/verify");
          } else {
            navigate("/tenant");
          }
        } else {
          if (errorCode === errorCodes.NOT_FOUND) {
            showErrorMessage(
              "Email not registered. Please contact your tenant to register your email"
            );
          } else {
            showErrorMessage("Something went wrong");
          }
        }
      } catch (err) {
        // eslint-disable-next-line no-empty
      }
      setLoading(false);
    },
  });

  if (isLoggedIn && isSessionVerified) {
    navigate("/tenant");
  } else if (isLoggedIn) {
    navigate("/verify");
  }

  return (
    <PDForm label="Login" form={loginForm}>
      <Grid container spacing={3} sx={{ width: 700}}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Email Address*</Typography>
          <PDTextField
            margin="normal"
            size="medium"
            required
            fullWidth
            name="email"
            autoComplete="email"
            variant="outlined"
            value={loginForm.values.email}
            onChange={loginForm.handleChange}
            autoFocus
            helperText="Enter your email to sign in!"
          />
          {loginForm?.errors.email && loginForm.touched.email && (
            <ErrorLabel>{loginForm?.errors.email}</ErrorLabel>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Password*</Typography>
          <PDTextField
            margin="normal"
            size="medium"
            required
            fullWidth
            name="password"
            autoComplete="email"
            variant="outlined"
            type="password"
            value={loginForm.values.password}
            onChange={loginForm.handleChange}
          />
          {loginForm?.errors.password && loginForm.touched.password && (
            <ErrorLabel>{loginForm?.errors.password}</ErrorLabel>
          )}
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox checked={loginForm.values.rememberMe} color="primary" />
            }
            label="Keep me logged in"
            name="rememberMe"
            sx={{ pl: "10px" }}
            onChange={loginForm.handleChange}
          />
        </Grid>
        <Grid item xs={12}>
          <PDLoadingButton
            loading={loading}
            variant="contained"
            color="primary"
            size="large"
            type="submit"
            label="Sign In"
            fullWidth
          />
        </Grid>
      </Grid>
    </PDForm>
  );
};
