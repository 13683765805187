import React from 'react';
import { Grid, CircularProgress, Typography } from '@mui/material';

export const PDLoadingStatus = ({ size }) => {
  const loadingSize = size === 'small' ? 50 : 100;

  return (
    <Grid
      container
      spacing={3}
      sx={{
        textAlign: 'center',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        justifyItems: 'center',
        height: '100vh',
      }}
    >
      <Grid item xs={12}>
        <CircularProgress color="success" size={loadingSize} />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h4">Loading...</Typography>
      </Grid>
    </Grid>
  );
};
