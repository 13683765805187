import { Outlet, useNavigate } from "react-router-dom";
import React, { useState } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import BusinessIcon from "@mui/icons-material/Business";
import SportsScoreIcon from "@mui/icons-material/SportsScore";
import StreetviewIcon from "@mui/icons-material/Streetview";

import { Drawer, List, Stack } from "@mui/material";
import { useAppContext } from "src/hooks/useAppContext";
import { Footer } from "./Main/components";

const drawer = ({ navigate }) => {
  //get current path
  const currentPath = window.location.pathname;

  return (
    <div>
      <List>
        {[
          { label: "My Organization", path: "/tenant", icon: <BusinessIcon /> },
          {
            label: "Organization Goals",
            path: "/tenant/goals",
            icon: <SportsScoreIcon />,
          },
          {
            label: "Strategic Plans",
            path: "/tenant/plans",
            icon: <StreetviewIcon />,
          },
        ].map(({ label, icon, path }) => (
          <ListItem key={label} selected={path === currentPath}>
            <ListItemButton onClick={() => navigate(path)}>
              <ListItemIcon>{icon}</ListItemIcon>
              <ListItemText primary={label} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export const SidebarLayout = ({ children }) => {
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.down("sm"), {
    defaultMatches: true,
  });

  const navigate = useNavigate();

  const { showSideBar, toggleSideBar } = useAppContext();

  const container = undefined;

  const drawerWidth = showSideBar ? 240 : 0;

  const [_, setIsClosing] = useState(false);

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  return (
    <Stack direction="row">
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      >
        {isSm && (
          <Drawer
            container={container}
            variant="temporary"
            open={showSideBar}
            onTransitionEnd={handleDrawerTransitionEnd}
            onClose={toggleSideBar}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
            sx={{
              display: { xs: "block", sm: "none" },
              "& .MuiDrawer-paper": {
                boxSizing: "border-box",
                width: drawerWidth,
                overflow: "hidden",
              },
            }}
          >
            {drawer({ navigate })}
          </Drawer>
        )}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              marginTop: "64px",
              overflow: "hidden",
              boxSizing: "border-box",
            },
          }}
        >
          {drawer({ navigate })}
        </Drawer>
      </Box>
      <Box sx={{ pl: 2, pr: 2 }}>
        {children}
      </Box>
    </Stack>
  );
};
