

const organizationTypes = [
    "For-Profit Business",
    "Not-For-Profit Business",
    "Non Profit Business",
    "Government Entity",
    "Educational Institution",
    "Other Non-Business Organization",
    "Personal",
]

const employeeCounts = [
    "0-1",
    "2-10",
    "11-50",
    "51-200",
    "201-500",
    "501-1000",
    "1001-5000",
    "5001-10000",
    "10001+",
]

const industries = [
    "Agriculture, Foresty, Fishing, and Hunting",
    "Mining, Quarrying, and Oil and Gas Extraction",
    "Utilities",
    "Construction",
    "Manufacturing",
    "Wholesale Trade",
    "Retail Trade",
    "Transportation and Warehousing",
    "Information",
    "Finance and Insurance",
    "Real Estate and Rental and Leasing",
    "Professional, Scientific, and Technical Services",
    "Management of Companies and Enterprises",
    "Administrative and Support and Waste Management and Remediation Services",
    "Educational Services",
    "Health Care and Social Assistance",
    "Arts, Entertainment, and Recreation",
    "Accommodation and Food Services",
    "Other Services (except Public Administration)",
    "Public Administration(not covered in economic census)",
]

const organizationStructureOptions = [
    { value: 1, label: "Very Flat" },
    { value: 2, label: "Flat" },
    { value: 3, label: "Somewhat Flat" },
    { value: 4, label: "Somewhat Hierarchical" },
    { value: 5, label: "Hierarchical" },
    { value: 6, label: "Very Hierarchical" },
]

const organizationCultureOptions = [
    { value: 1, label: "Very Collabrative" },
    { value: 2, label: "Collabrative" },
    { value: 3, label: "Somewhat Collabrative" },
    { value: 4, label: "Somewhat Individualistic" },
    { value: 5, label: "Individualistic" },
    { value: 6, label: "Very Individualistic" },
]

const organizationMaturityOptions = [
    { value: 1, label: "Very Innovative" },
    { value: 2, label: "Innovative" },
    { value: 3, label: "Somewhat Innovative" },
    { value: 4, label: "Somewhat Established" },
    { value: 5, label: "Established" },
    { value: 6, label: "Very Established" },
]

const organizationFinancesOptions = [
    { value: 1, label: "Very Growth-Oriented" },
    { value: 2, label: "Growth-Oriented" },
    { value: 3, label: "Somewhat Growth-Oriented" },
    { value: 4, label: "Somewhat Stability-Focused" },
    { value: 5, label: "Stability-Focused" },
    { value: 6, label: "Very Stability-Focused" },
]


module.exports = {
    organizationTypes,
    employeeCounts,
    industries,
    organizationStructureOptions,
    organizationCultureOptions,
    organizationMaturityOptions,
    organizationFinancesOptions,
}