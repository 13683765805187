import { useState, useMemo, useCallback } from "react";

export const EDIT_ACTION = "EDIT";
export const VIEW_ACTION = "VIEW";

export const useForm = ({ allowActions = true, defaultMode = EDIT_ACTION }) => {
  const [mode, setMode] = useState(defaultMode);
  const [hasChanges, setHasChanges] = useState(false);

  const switchToEdit = useCallback(() => {
    setMode(EDIT_ACTION);
  }, [setMode]);

  const canSwitchToView = useMemo(() => mode === EDIT_ACTION, [mode]);

  const switchToView = useCallback(() => {
    setMode(VIEW_ACTION);
  }, [setMode]);

  return {
    mode,
    hasChanges,
    setHasChanges,
    switchToEdit,
    canSwitchToView,
    switchToView,
    allowActions,
    readOnly: mode === VIEW_ACTION,
  };
};
