import { useSnackbar } from "notistack";
import React, { useCallback } from "react";

export const useSnackbarUtils = () => {
  const { enqueueSnackbar } = useSnackbar();

  const showSuccessMessage = useCallback(
    (message) => {
      enqueueSnackbar(message, { variant: "success" });
    },
    [enqueueSnackbar]
  );

  const showErrorMessage = useCallback(
    (message) => {
      enqueueSnackbar(message, { variant: "error" });
    },
    [enqueueSnackbar]
  );

  const showWarningMessage = useCallback(
    (message) => {
      enqueueSnackbar(message, { variant: "warning" });
    },
    [enqueueSnackbar]
  );

  return {
    showSuccessMessage,
    showErrorMessage,
    showWarningMessage,
  };
};
