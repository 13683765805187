import React from "react";

import { Routes, Route } from "react-router-dom";

import { Home } from "./Home";
import { Login } from "./user/Login";
import { VerifyEmail } from "./user/VerifyEmail";
import { Page } from "./common/Page";
import { NotFound } from "./Notfound";
import { TenantRoot } from "./tenants/TenantRoot";

export const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Page />}>
        <Route index element={<Home />} />
        <Route path=":hint?" element={<Home />} />
        <Route exact path="/login" element={<Login />} />
        {/* <Route exact path="/training" element={<AIConfiguration />} /> */}
        <Route path="/tenant/*" element={<TenantRoot />} />
        <Route path="/verify/:code?" element={<VerifyEmail />} />
        <Route path="/verify" element={<VerifyEmail />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Routes>
  );
};
