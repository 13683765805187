import React, { useMemo, useState } from "react";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { App } from "./App";
import { useSessionManagementQuery } from "src/hooks/useSessionManagementQuery";
import { AppContext } from "./contexts/AppContext";
import { AuthContext } from "./contexts/AuthContext";
import { PDLoadingStatus } from "./common/PDLoadingStatus";
import { SnackbarProvider } from "./components/snackbar";
import { Helmet } from "react-helmet";
import { useTenantQuery } from "./hooks/useTenantQuery";
import { TenantContext } from "./contexts/TenantContext";

export const Root = () => {
  const currentPath = window.location.pathname;
  const [showSideBar, setShowSideBar] = useState(true);
  const [title, setTitle] = useState("Signis Home");

  const toggleSideBar = () => {
    setShowSideBar((s) => !s);
  };

  const { hasSideBar } = useMemo(() => {
    const hasSideBar = currentPath.startsWith("/tenant");

    return { hasSideBar };
  }, [currentPath]);

  const {
    isLoggedIn,
    isSessionVerified,
    isGlobalAdmin,
    isTenantAdmin,
    isTenantUser,
    session,
    startUserSession,
    verifySession,
    endUserSession,
    isLoading: checkingSession,
    user,
  } = useSessionManagementQuery();

  const {
    tenantId,
    tenantName,
    domain,
    logo,
    tenantType,
    isUnderTenantSpecificDomain,
    checkingTenant,
  } = useTenantQuery();

  if (checkingSession || checkingTenant) {
    return <PDLoadingStatus />;
  }

  const authContext = {
    isLoggedIn,
    isSessionVerified,
    isGlobalAdmin,
    isTenantAdmin,
    session,
    user,
    startUserSession,
    verifySession,
    endUserSession,
    isTenantUser,
    logout: () => {
    },
  };

  const appContext = {
    showSideBar,
    hasSideBar,
    toggleSideBar,
    setTitle,
  };

  const tenantContext = {
    tenantId,
    tenantName,
    domain,
    logo,
    tenantType,
    isUnderTenantSpecificDomain,
    checkingTenant,
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <AppContext.Provider value={appContext}>
        <TenantContext.Provider value={tenantContext}>
          <AuthContext.Provider value={authContext}>
            <SnackbarProvider>
              <Helmet>
                <title>{title}</title>
              </Helmet>
              <App />
            </SnackbarProvider>
          </AuthContext.Provider>
        </TenantContext.Provider>
      </AppContext.Provider>
    </LocalizationProvider>
  );
};
