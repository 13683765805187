import { gql } from "@apollo/client";

export const updateTenantMutation = gql`
  mutation updateTenant(
    $tenantName: String
    $name: String
    $organizationType: String
    $employeeCount: String
    $market: String
    $industry: String
    $organizationPriorities: JSON
    $organizationStory: String
  ) {
    updateTenant(
      tenantName: $tenantName
      name: $name
      organizationType: $organizationType
      employeeCount: $employeeCount
      market: $market
      industry: $industry
      organizationPriorities: $organizationPriorities
      organizationStory: $organizationStory
    ) {
      id
      tenantName
      name
      organizationType
      employeeCount
      market
      organizationPriorities
      organizationStory
      email
      address
    }
  }
`;
