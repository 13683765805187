const getListOptions = (list) => {
  return list.map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

const minValue = (list) => {
  return list.reduce(
    (min, p) => (p.value < min ? p.value : min),
    list[0].value
  );
};

const maxValue = (list) => {
  return list.reduce(
    (max, p) => (p.value > max ? p.value : max),
    list[0].value
  );
};

const getMinMaxMarks = (list) => {
  const min = minValue(list);
  const max = maxValue(list);

  return list.filter((item) => item.value === min || item.value === max);
};

const getMarkLabel = (list, value) => {
  return list.find((item) => item.value === value)?.label ?? "";
};

module.exports = {
    getListOptions,
    minValue,
    maxValue,
    getMinMaxMarks,
    getMarkLabel,
}
