import { gql } from "@apollo/client";

export const getPlanQuery = gql`
  query getPlan(
    $id: ID!
  ) {
    getPlan(
      id: $id
    ) {
      id
      name
      description
    }
  }
`;
