import * as React from 'react';
import { FormLabel } from '@mui/material';

export const ErrorLabel = ({ children, sx = {} }) => {
  return (
    <FormLabel
      component={'div'}
      sx={{ fontStyle: 'italic', fontSize: '11px', paddingLeft: '5px', ...sx }}
      error
    >
      {children}
    </FormLabel>
  );
};
