import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "src/routes/hooks";
import { createPlanMutation } from "./graphql/createPlan";
import { getPlansQuery } from "./graphql/getPlans";
import { EDIT_ACTION, useForm, VIEW_ACTION } from "src/components/hooks/useForm";
import { planSchema } from "@pbhapp/common/validations/tenantValidations";
import { useNavigate } from "react-router-dom";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
import { useFormik } from "formik";
import { PDForm } from "src/components/PDForm";
import {
  Grid,
  Typography,
  Slider,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { PDTextField } from "src/components/PDTextField";
import { PDErrorLabel } from "src/components/PDErrorLabel";

export const NewPlan = () => {
  const { showErrorMessage, showSuccessMessage } = useSnackbarUtils();
  const navigate = useNavigate();

  const formContext = useForm({ allowActions: true, defaultMode: EDIT_ACTION });

  const [createPlan, { loading: createPlanLoading }] = useMutation(createPlanMutation, {
    onCompleted: () => {
      showSuccessMessage("Plan created successfully");
      navigate("/tenant/plans");
    },
    onError: () => {
      showErrorMessage("Something went wrong, please try again");
    },
    refetchQueries: [
      getPlansQuery, // DocumentNode object parsed with gql
      "getPlansQuery", // Query name
    ],
  });

  const planForm = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    enableReinitialize: true,
    validationSchema: planSchema,
    onSubmit: async (values) => {
      await createPlan({
        variables: {
          name: values.name,
          description: values.description,
        },
      });
    },
  });

  const loading = createPlanLoading;

  const { readOnly } = formContext;

  return (
    <PDForm
      label="Create Plan"
      showActionButtons={true}
      {...formContext}
      form={planForm}
      formType="new"
      handleSave={planForm.handleSubmit}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Name</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            fullWidth
            name="name"
            value={planForm.values.name}
            onChange={planForm.handleChange}
            autoFocus
            variant="standard"
            sx={{ width: "400px" }}
          />
          <PDErrorLabel form={planForm} field={"name"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Description</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            multiline
            minRows={6}
            fullWidth
            disabled={readOnly}
            name="description"
            value={planForm.values.description}
            onChange={planForm.handleChange}
            autoFocus
            variant="outlined"
            placeholder="Describe the plan"
            sx={{ width: "750px" }}
          />
          <PDErrorLabel form={planForm} field={"description"} />
        </Grid>
      </Grid>
    </PDForm>
  );
};
