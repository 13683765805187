import React from "react";
import { Box } from "@mui/material";
import { Main } from "../layouts";
import { Outlet } from "react-router-dom";

export const Page = () => {
  return (
    <Main bgcolor={"background.paper"}>
      <Box
        component="main"
        sx={{
          flexDirection: "column",
          minHeight: 1,
          bgcolor: "alternate.main",
        }}
      >
        <Outlet />
      </Box>
    </Main>
  );
};
