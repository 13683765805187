import { gql } from "@apollo/client";

export const updateGoalMutation = gql`
  mutation updateGoal(
    $id: ID!
    $name: String!
    $description: String!
  ) {
    updateGoal(
      id: $id
      name: $name
      description: $description
    ) {
      id
      name
      description
    }
  }
`;
