import {
  Grid,
  Typography,
  Slider,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { useFormik } from "formik";
import { PDErrorLabel } from "src/components/PDErrorLabel";
import { PDSelect } from "src/components/PDSelect";
import { PDTextField } from "src/components/PDTextField";
import {
  organizationTypes,
  employeeCounts,
  industries,
  organizationFinancesOptions,
  organizationCultureOptions,
  organizationStructureOptions,
  organizationMaturityOptions,
} from "@pbhapp/common/constants/data";
import {
  getListOptions,
  minValue,
  maxValue,
  getMinMaxMarks,
  getMarkLabel,
} from "@pbhapp/common/utility/formUtils";
import { tenantDetailsSchema } from "@pbhapp/common/validations/tenantValidations";
import { BasicMark } from "src/components/BasicMark";
import { ErrorLabel } from "src/common/forms/ErrorLabel";
import { useMutation } from "@apollo/client";
import { updateTenantMutation } from "./graphql/updateTenant";
import { PDLoadingStatus } from "src/common/PDLoadingStatus";
import { getTenant } from "./graphql/getTenant";
import { useQuery } from "@apollo/client";
import { PDForm } from "src/components/PDForm";
import { useForm, VIEW_ACTION } from "src/components/hooks/useForm";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
export const TenantHome = () => {
  const {
    loading: getTenantLoading,
    error: getTenantError,
    data: getTenantData,
  } = useQuery(getTenant);

  const { showErrorMessage, showSuccessMessage } = useSnackbarUtils();

  const formContext = useForm({ allowActions: true, defaultMode: VIEW_ACTION });
  const { readOnly, switchToView } = formContext;

  const [
    updateTenant,
    { loading: updateTenantLoading, error: updateTenantError },
  ] = useMutation(updateTenantMutation, {
    onCompleted: () => {
      showSuccessMessage("Tenant details updated successfully");
      switchToView();
    },
    onError: () => {
      showErrorMessage("Something went wrong, please try again");
    },
    refetchQueries: [
      getTenant, // DocumentNode object parsed with gql
      "getTenant", // Query name
    ],
  });

  const { getTenant: tenant } = getTenantData ?? {};

  const {
    tenantName,
    organizationType,
    employeeCount,
    market,
    organizationPriorities = {},
    organizationStory,
    industry,
  } = tenant ?? {};

  const {
    organizationStructure,
    organizationCulture,
    organizationMaturity,
    organizationFinances,
  } = organizationPriorities ?? {};

  const tenantForm = useFormik({
    initialValues: {
      tenantName: tenantName ?? "",
      organizationType: organizationType ?? "",
      employeeCount: employeeCount ?? "",
      market: market ?? "",
      industry: industry ?? "",
      organizationStory: organizationStory ?? "",
      organizationStructure: organizationStructure ?? 1,
      organizationCulture: organizationCulture ?? 1,
      organizationMaturity: organizationMaturity ?? 1,
      organizationFinances: organizationFinances ?? 1,
    },
    enableReinitialize: true,
    validationSchema: tenantDetailsSchema,
    onSubmit: async (values) => {
      await updateTenant({
        variables: {
          tenantName: values.tenantName,
          organizationType: values.organizationType,
          employeeCount: values.employeeCount,
          market: values.market,
          industry: values.industry,
          organizationPriorities: {
            organizationStructure: values.organizationStructure,
            organizationCulture: values.organizationCulture,
            organizationMaturity: values.organizationMaturity,
            organizationFinances: values.organizationFinances,
          },
          organizationStory: values.organizationStory,
        },
      });
    },
  });

  const error = getTenantError;
  const loading = getTenantLoading || updateTenantLoading;

  if (loading) {
    return <PDLoadingStatus />;
  }

  if (error) {
    showErrorMessage("Something went wrong, please try again");
  }

  return (
    <PDForm
      label="My Organization"
      showActionButtons={true}
      {...formContext}
      form={tenantForm}
      handleSave={tenantForm.handleSubmit}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>

      <Grid item xs={12}>
          <Typography variant="h4">Organization Details</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Organization Name</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            fullWidth
            name="tenantName"
            autoComplete="tenantName"
            value={tenantForm.values.tenantName}
            onChange={tenantForm.handleChange}
            autoFocus
            disabled={readOnly}
            variant="standard"
            sx={{ width: "400px" }}
          />
          <PDErrorLabel form={tenantForm} field={"tenantName"} />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Typography variant="subtitle1">Organization Type</Typography>
          <PDSelect
            variant="outlined"
            name="organizationType"
            disabled={readOnly}
            onValueChange={(value) => {
              tenantForm.setFieldValue("organizationType", value);
            }}
            sx={{ width: "500px", pt: 1.4 }}
            selectedValue={tenantForm.values.organizationType}
            options={getListOptions(organizationTypes)}
          />
          <PDErrorLabel form={tenantForm} field={"organizationType"} />
        </Grid>
        <Grid item xs={1} sx={{ display: { sx: 'none', lg: 'block'}, pr: 0.5, pl: 0.5}}>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Typography variant="subtitle1">Employee Count</Typography>
          <PDSelect
            variant="outlined"
            name="employeeCount"
            disabled={readOnly}
            id="employeeCount"
            onValueChange={(value) => {
              tenantForm.setFieldValue("employeeCount", value);
            }}
            sx={{ width: "500px" }}
            selectedValue={tenantForm.values.employeeCount}
            options={getListOptions(employeeCounts)}
          />
          <PDErrorLabel form={tenantForm} field={"employeeCount"} />
        </Grid>
        <Grid item xs={12} lg={5}>
          <Typography variant="subtitle1">Industry</Typography>
          <PDSelect
            variant="outlined"
            name="industry"
            disabled={readOnly}
            onValueChange={(value) => {
              tenantForm.setFieldValue("industry", value);
            }}
            sx={{ width: "500px" }}
            selectedValue={tenantForm.values.industry}
            options={getListOptions(industries)}
          />
          <PDErrorLabel form={tenantForm} field={"industry"} />
        </Grid>
        <Grid item xs={1} sx={{ display: { sx: 'none', lg: 'block'}, pr: 0.5, pl: 0.5}}>
        </Grid>
        <Grid item xs={12} lg={5}>
          <Typography variant="subtitle1">Market</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            required
            fullWidth
            name="market"
            disabled={readOnly}
            value={tenantForm.values.market}
            onChange={tenantForm.handleChange}
            autoFocus
            variant="standard"
            sx={{ width: "500px" }}
          />
          <PDErrorLabel form={tenantForm} field={"market"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ pt: 2}}>Organization Priorities</Typography>
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">Organization Structure</Typography>
          <Slider
            step={1}
            disabled={readOnly}
            color="info"
            name={"organizationStructure"}
            marks={getMinMaxMarks(organizationStructureOptions)}
            min={minValue(organizationStructureOptions)}
            max={maxValue(organizationStructureOptions)}
            valueLabelDisplay="auto"
            getAriaLabel={(index) =>
              getMarkLabel(organizationStructureOptions, index)
            }
            getAriaValueText={(value) =>
              getMarkLabel(organizationStructureOptions, value)
            }
            valueLabelFormat={(value) =>
              getMarkLabel(organizationStructureOptions, value)
            }
            value={tenantForm.values.organizationStructure}
            onChange={tenantForm.handleChange}
            sx={{ width: "450px", m: 1 }}
            label="Organization Structure"
          />
          <ErrorLabel form={tenantForm} field={"organizationStructure"} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">Organization Culture</Typography>
          <Slider
            step={1}
            color="info"
            disabled={readOnly}
            name={"organizationCulture"}
            marks={getMinMaxMarks(organizationCultureOptions)}
            min={minValue(organizationCultureOptions)}
            max={maxValue(organizationCultureOptions)}
            valueLabelDisplay="auto"
            getAriaLabel={(index) =>
              getMarkLabel(organizationCultureOptions, index)
            }
            getAriaValueText={(value) =>
              getMarkLabel(organizationCultureOptions, value)
            }
            valueLabelFormat={(value) =>
              getMarkLabel(organizationCultureOptions, value)
            }
            value={tenantForm.values.organizationCulture}
            onChange={tenantForm.handleChange}
            slots={{ markLabel: BasicMark }}
            sx={{ width: "450px", m: 1 }}
          />
          <ErrorLabel form={tenantForm} field={"organizationCulture"} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">Organization Maturity</Typography>
          <Slider
            step={1}
            color="info"
            disabled={readOnly}
            name={"organizationMaturity"}
            marks={getMinMaxMarks(organizationMaturityOptions)}
            min={minValue(organizationMaturityOptions)}
            max={maxValue(organizationMaturityOptions)}
            valueLabelDisplay="auto"
            getAriaLabel={(index) =>
              getMarkLabel(organizationMaturityOptions, index)
            }
            getAriaValueText={(value) =>
              getMarkLabel(organizationMaturityOptions, value)
            }
            valueLabelFormat={(value) =>
              getMarkLabel(organizationMaturityOptions, value)
            }
            value={tenantForm.values.organizationMaturity}
            onChange={tenantForm.handleChange}
            slots={{ markLabel: BasicMark }}
            sx={{ width: "450px", m: 1 }}
          />
          <ErrorLabel form={tenantForm} field={"organizationMaturity"} />
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography variant="subtitle1">Organization Finances</Typography>
          <Slider
            step={1}
            color="info"
            disabled={readOnly}
            name={"organizationFinances"}
            marks={getMinMaxMarks(organizationFinancesOptions)}
            min={minValue(organizationFinancesOptions)}
            max={maxValue(organizationFinancesOptions)}
            valueLabelDisplay="auto"
            getAriaLabel={(index) =>
              getMarkLabel(organizationFinancesOptions, index)
            }
            getAriaValueText={(value) =>
              getMarkLabel(organizationFinancesOptions, value)
            }
            valueLabelFormat={(value) =>
              getMarkLabel(organizationFinancesOptions, value)
            }
            value={tenantForm.values.organizationFinances}
            onChange={tenantForm.handleChange}
            slots={{ markLabel: BasicMark }}
            sx={{ width: "450px", m: 1 }}
          />
          <ErrorLabel form={tenantForm} field={"organizationFinances"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h4" sx={{ pt: 2}}>Organization Story</Typography>
        </Grid>
        <Grid item xs={12}>
          <PDTextField
            margin="dense"
            size="xlarge"
            multiline
            minRows={6}
            fullWidth
            disabled={readOnly}
            name="organizationStory"
            value={tenantForm.values.organizationStory}
            onChange={tenantForm.handleChange}
            autoFocus
            variant="outlined"
            placeholder="Tell us about your organization"
            helperText="Think about how you would describe the organization to a new or prospective hire - what are its purpose and values?"
            sx={{ width: "750px" }}
          />
          <PDErrorLabel form={tenantForm} field={"organizationStory"} />
        </Grid>
      </Grid>
    </PDForm>
  );
};
