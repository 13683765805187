import {
  Box,
  Button,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Container from "./components/Container";
import NotFoundImageSrc from './assets/notFound.svg';

export const NotFound = ({ errorCode = "404", errorMessage = "Oops! Looks like you followed a bad link."}) => {
  
  const theme = useTheme();
  
  const isMd = useMediaQuery(theme.breakpoints.up("md"), {
    defaultMatches: true,
  });

  return (
    <Box
      position={"relative"}
      minHeight={"calc(100vh - 247px)"}
      display={"flex"}
      alignItems={"center"}
      justifyContent={"center"}
      height={1}
      marginTop={-12}
      paddingTop={12}
    >
      <Container>
        <Grid container>
          <Grid
            item
            container
            alignItems={"center"}
            justifyContent={"center"}
            xs={12}
            md={6}
          >
            <Box>
              <Typography
                variant="h1"
                component={"h1"}
                align={isMd ? "left" : "center"}
                sx={{ fontWeight: 700 }}
              >
                {errorCode}
              </Typography>
              <Typography
                variant="h6"
                component="p"
                color="text.secondary"
                align={isMd ? "left" : "center"}
              >
                {errorMessage}
              </Typography>
              <Box
                marginTop={4}
                display={"flex"}
                justifyContent={{ xs: "center", md: "flex-start" }}
              >
                <Button
                  component={Link}
                  variant="contained"
                  color="primary"
                  size="large"
                  href={"/"}
                >
                  Back home
                </Button>
              </Box>
            </Box>
          </Grid>
          <Grid item container justifyContent={"center"} xs={12} md={6}>
            <Box height={1} width={1} maxWidth={500}>
              <Box
                component={"img"}
                src={NotFoundImageSrc}
                width={1}
                height={1}
                sx={{
                  filter:
                    theme.palette.mode === "dark" ? "brightness(0.8)" : "none",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
