/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { verifyEmail } from "../api/auth";
import { VerificationCode } from "../components/VerificationCode";
import { useAuthContext } from "src/hooks/useAuthContext";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
import * as SessionStatus from "@pbhapp/common/enums/sessionStatus";

export const VerifyEmail = () => {
  const navigate = useNavigate();
  const {
    isLoggedIn,
    sessionStatus,
    verifySession,
  } = useAuthContext() ?? {};

  const { showErrorMessage } = useSnackbarUtils();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!isLoggedIn) {
      navigate("/login");
    }

    if (sessionStatus === SessionStatus.VERIFIED) {
      navigate("/tenant");
    }
  }, []);

  const confirmVerificationCode = async (verificationCode) => {
    setLoading(true);
    const result = await verifyEmail({
      verificationCode,
    });

    const {
      success,
      sessionStatus,
      user: {
        userType,
        userAccess,
        accountStatus,
        name,
        details,
        phone,
      } = {}
    } = result ?? {};

    if (success && sessionStatus === SessionStatus.VERIFIED) {
      verifySession({
        sessionStatus,
        user: {
          accountStatus,
          userType,
          userAccess,
          phone,
          name,
          details,
        }
      });
      navigate("/tenant");
    } else {
      showErrorMessage(
        "Could not verify the email, please confirm the verification code sent in the email"
      );
    }

    setLoading(false);

    return { success };
  };

  return (
    <VerificationCode
      title="Verify Email"
      loading={loading}
      onCheckVerificationCode={confirmVerificationCode}
    />
  );
};
