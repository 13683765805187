import React from "react";
import { Stack, Box, useTheme } from "@mui/material";
import styled from "styled-components";

const Footer = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{ height: "32px", borderTop: `solid 1px ${theme.palette.divider}` }}
    >
      <Stack spacing={4} p={1} direction="row" justifyContent="center">
        <Div2>Terms & Conditions</Div2>
        <Div3>Privacy Policy</Div3>
        <Div4>License</Div4>
      </Stack>
    </Box>
  );
};

const Div2 = styled.div`
  color: var(--gray-500, #718096);
  text-align: center;
  align-self: start;
  white-space: nowrap;
  font:
    500 14px/24px Plus Jakarta Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const Div3 = styled.div`
  color: var(--gray-500, #718096);
  text-align: center;
  font:
    500 14px/24px Plus Jakarta Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
`;

const Div4 = styled.div`
  color: var(--gray-500, #718096);
  text-align: center;
  align-self: start;
  white-space: nowrap;
  font:
    500 14px/24px Plus Jakarta Sans,
    -apple-system,
    Roboto,
    Helvetica,
    sans-serif;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

export default Footer;
