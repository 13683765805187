import { gql } from "@apollo/client";

export const getGoalQuery = gql`
  query getGoal(
    $id: ID!
  ) {
    getGoal(
      id: $id
    ) {
      id
      name
      description
    }
  }
`;
