import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useTheme } from "@mui/material/styles";
import { useTenantContext } from "src/hooks/useTenantContext";

import {
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Stack,
  styled,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  AccountCircle,
  Menu as MenuIcon,
  MenuOpen as MenuOpenIcon,
} from "@mui/icons-material";
import { leave } from "src/api/auth";
import { useAuthContext } from "src/hooks/useAuthContext";
import Logo from "src/components/logo";
import { useAppContext } from "src/hooks/useAppContext";

const MenuButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 700,
  borderRadius: 0,
  minWidth: "80px",
  borderBottom: `solid 1px rgb(0, 0, 0, 0.12)`,
  borderTop: `solid 1px ${theme.palette.text.primary}`,
  borderLeft: `solid 1px ${theme.palette.text.primary}`,
  borderRight: `solid 1px ${theme.palette.text.primary}`,
  height: "52px",
  marginLeft: 4,
  padding: 0,
  "&.MuiButton-outlined": {
    color: "white",
    border: `solid 1px ${theme.palette.text.primary}`,
  },
  "&:hover": {
    textDecoration: "underline",
    color: "white",
    backgroundColor: theme.palette.text.primary,
  },
}));

const CurrentMenuButton = styled(Button)(({ theme }) => ({
  fontWeight: 700,
  borderRadius: 0,
  color: "white",
  minWidth: "80px",
  backgroundColor: theme.palette.text.primary,
  height: "52px",
  marginLeft: 4,
  padding: 0,
  "&.MuiButton-outlined": {
    color: "white",
    border: `solid 1px ${theme.palette.text.primary}`,
  },
  "&:hover": {
    textDecoration: "underline",
  },
}));

const hasActiveLink = ({ href, pathname }) => href === pathname;

const renderMenuButton = ({ href, label, pathname }) => {
  if (!hasActiveLink({ href, pathname })) {
    return <MenuButton href={href}>{label}</MenuButton>;
  }

  return <CurrentMenuButton href={"/landing"}>Home</CurrentMenuButton>;
};

const renderMenu = ({ pathname, menu = [] }) => {
  return menu.map(({ label, href }) =>
    renderMenuButton({ href, label, pathname })
  );
};

const Topbar = ({ colorInvert = false }) => {
  const theme = useTheme();
  const { tenantName, isUnderTenantSpecificDomain } =
    useTenantContext();

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const handleAccountMenuClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleAccountClick = (event) => {
    navigate("/account");
  };

  const handleLogoutClick = async (event) => {
    await leave();
    document.location.href = "/?loggedOut";
  };

  const handleAccountClose = (event) => {
    setAnchor(null);
  };

  const { isSessionVerified: isLoggedIn, user: { email } = {} } =
    useAuthContext();

  const { hasSideBar = false, showSideBar, toggleSideBar } = useAppContext();

  return (
    <Box
      display={"flex"}
      justifyContent={"space-between"}
      width={1}
      sx={{ flexGrow: 1 }}
    >
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          padding: isLoggedIn ? 0 : 1,
        }}
      >
        {hasSideBar && (
          <IconButton>
            {showSideBar ? (
              <MenuOpenIcon
                fontSize="large"
                onClick={toggleSideBar}
                sx={{
                  color: theme.palette.alternate.primary,
                  width: "24px",
                  height: "24px",
                }}
              />
            ) : (
              <MenuIcon
                fontSize="large"
                onClick={toggleSideBar}
                sx={{
                  color: theme.palette.alternate.primary,
                  width: "24px",
                  height: "24px",
                }}
              />
            )}
          </IconButton>
        )}
        <Link to={"/"}>
          {isUnderTenantSpecificDomain && <h3 style={{color: theme.palette.alternate.primary,}}>{tenantName}</h3>}
          {!isUnderTenantSpecificDomain && <Logo />}
        </Link>
      </Stack>
      <Box alignItems={"center"}>
        {isLoggedIn && (
          <>
            <IconButton onClick={handleAccountMenuClick}>
              <AccountCircle
                fontSize="large"
                sx={{ color: theme.palette.alternate.primary }}
              />
            </IconButton>
            <Menu anchorEl={anchor} open={open} onClose={handleAccountClose}>
              <Paper sx={{ minWidth: 200, minHeight: 40, m: 1 }}>
                <MenuItem
                  sx={{ textOverflow: "ellipsis", fontWeight: 800 }}
                  onClick={handleAccountClick}
                >
                  {email}
                </MenuItem>
                <MenuItem onClick={handleLogoutClick} color="error">
                  Logout
                </MenuItem>
              </Paper>
            </Menu>
          </>
        )}
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  colorInvert: PropTypes.bool,
};

export default Topbar;
