import * as React from 'react';
import { Select, MenuItem, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';

export const PDSelect = ({
  id = 'pdselect',
  selectedValue,
  name="type",
  variant="outlined",
  disabled = false,
  onValueChange,
  options = [],
  ...props
}) => {

  return (
    <Select
      disabled={disabled}
      id={id}
      size="small"
      name={name}
      variant={variant}
      value={selectedValue}
      inputProps={{ readOnly: disabled }}
      onChange={(event) => {
        if (onValueChange) {
          onValueChange(event.target.value);
        }
      }}
      {...props}
    >
    {options.map(({ value, label, display }) => (
      <MenuItem value={value} key={value}>
        <Stack direction="row" justifyItems="center">
          <Typography variant="title">{display ?? label}</Typography>
        </Stack>
      </MenuItem>
    ))}
    </Select>
  );
};

PDSelect.props = {
  selectedValue: PropTypes.any,
  disabled: PropTypes.bool,
  onValueChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  props: PropTypes.object,
};
