import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "src/routes/hooks";
import { getGoalQuery } from "./graphql/getGoal";
import { updateGoalMutation } from "./graphql/updateGoal";
import { getGoalsQuery } from "./graphql/getGoals";
import { error } from "jquery";
import { useForm, VIEW_ACTION } from "src/components/hooks/useForm";
import { goalSchema } from "@pbhapp/common/validations/tenantValidations";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
import { useFormik } from "formik";
import { PDForm } from "src/components/PDForm";
import {
  Grid,
  Typography,
  Slider,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { PDTextField } from "src/components/PDTextField";
import { PDErrorLabel } from "src/components/PDErrorLabel";

export const Goal = () => {
  const { id } = useParams();
  const { showErrorMessage, showSuccessMessage } = useSnackbarUtils();

  const formContext = useForm({ allowActions: true, defaultMode: VIEW_ACTION });

  const { data, isLoading: getGoalLoading, error } = useQuery(getGoalQuery, {
    variables: {
      id,
    },
  });

  const [updateGoal, { loading: updateGoalLoading }] = useMutation(updateGoalMutation, {
    onCompleted: () => {
      showSuccessMessage("Tenant details updated successfully");
    },
    onError: () => {
      showErrorMessage("Something went wrong, please try again");
    },
    refetchQueries: [
      getGoalsQuery, // DocumentNode object parsed with gql
      "getGoals", // Query name
    ],
  });

  const { getGoal: goal } = data ?? {};

  const { name, description } = goal ?? {};

  const goalForm = useFormik({
    initialValues: {
      name: name ?? "",
      description: description ?? "",
    },
    enableReinitialize: true,
    validationSchema: goalSchema,
    onSubmit: async (values) => {
      await updateGoal({
        variables: {
          id,
          name: values.name,
          description: values.description,
        },
      });
    },
  });

  const loading = getGoalLoading || updateGoalLoading;

  if (error) {
    showErrorMessage("Something went wrong loading the goal, please try again");
  }

  const { readOnly } = formContext;

  return (
    <PDForm
      label="Update Goal"
      showActionButtons={true}
      {...formContext}
      form={goalForm}
      handleSave={goalForm.handleSubmit}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Name</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            fullWidth
            name="name"
            autoComplete="name"
            value={goalForm.values.name}
            onChange={goalForm.handleChange}
            autoFocus
            disabled={readOnly}
            variant="standard"
            sx={{ width: "400px" }}
          />
          <PDErrorLabel form={goalForm} field={"name"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Description</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            multiline
            minRows={6}
            fullWidth
            disabled={readOnly}
            name="description"
            value={goalForm.values.description}
            onChange={goalForm.handleChange}
            autoFocus
            variant="outlined"
            placeholder="Describe the goal"
            sx={{ width: "750px" }}
          />
          <PDErrorLabel form={goalForm} field={"description"} />
        </Grid>
      </Grid>
    </PDForm>
  );
};
