export const translationDict = {
  INVALID: 'value is invalid',
  INVALID_TYPE: 'value is invalid',
  REQUIRED: 'missing value',
  MAX_LENGTH: 'cannot be more than set length',
  MIN_LENGTH: 'less than the minimum length',
  PASSWORD_WEAK: 'not strong enough, should be atleast 8 characters',
  USER_CREATED_SUCCESSFULLY: 'User created successfully',
  EMAIL_ALREADY_REGISTERED: 'email already registered',
  USER_NOT_CREATED: 'User could not be created successfully',
};