const tenantTypes = {
  ROOT: "ROOT",
  ADMIN: "ADMIN",
  TRIAL: "TRIAL",
  FREE: "FREE",
  TIER_1: "TIER_1",
  TIER_2: "TIER_2",
  TIER_3: "TIER_3",
};

module.exports = tenantTypes;
