/* eslint-disable */
import React from "react";
import { Typography, Box, Link, Stack } from "@mui/material";
import { useFormik } from "formik";
import { ErrorLabel } from "../common/forms/ErrorLabel";
import { PDForm } from "./PDForm";
import VerificationInput from "react-verification-input";
import { PDLoadingButton } from "./PDLoadingButton";

export const VerificationCode = ({
  title,
  onCheckVerificationCode,
  loading,
  onClickResend,
}) => {
  const verificationCodeForm = useFormik({
    initialValues: {
      verificationCode: "",
    },
    onSubmit: async (values) => {
      await onCheckVerificationCode(values.verificationCode);
    },
  });

  return (
    <PDForm label={title} form={verificationCodeForm}>
      <Box
        component="form"
        onSubmit={verificationCodeForm.handleSubmit}
        noValidate
        sx={{ mt: 1 }}
      >
        <Typography variant="h6">
          Please enter the verification code that is sent to your email.
        </Typography>
        <Stack
          spacing={2}
          sx={{
            p: 2,
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
          }}
        >
          <VerificationInput
            onChange={(value) =>
              verificationCodeForm.setFieldValue("verificationCode", value)
            }
            onComplete={verificationCodeForm.handleSubmit}
            value={verificationCodeForm.values.verificationCode}
            autoFocus
            validChars="0-9"
          />

          {onClickResend && (
            <Typography variant="title">
              If you did not get any verification email please{"   "}
              <Link
                autoFocus
                onClick={onClickResend}
                type="button"
                component="button"
                variant="body2"
                underline="always"
              >
                click here
              </Link>
              {"   "}
              to resend.
            </Typography>
          )}
        </Stack>
        <Box>
          {verificationCodeForm.errors.verificationCode &&
            verificationCodeForm.touched.verificationCode && (
              <ErrorLabel>
                {verificationCodeForm.errors.verificationCode}
              </ErrorLabel>
            )}
        </Box>
        <Stack alignItems="center">
          <PDLoadingButton
            type="submit"
            variant="contained"
            color="primary"
            sx={{ mt: 3, mb: 2, minWidth: "150px", align: "center" }}
            loading={loading}
            label="Verify"
          />
        </Stack>
      </Box>
    </PDForm>
  );
};
