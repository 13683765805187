import { executeAPI } from './common';

export const enter = async ({ email, password }) => {
  return await executeAPI('/api/users/enter', { email, password });
};

export const leave = async () => {
  return await executeAPI('/api/users/leave');
};

export const forgotPassword = async ({ email }) => {
  return await executeAPI('/api/users/forgotPassword', { email });
};

export const registerUser = async ({ name, email, password }) => {
  return await executeAPI('/api/users/createUser', { name, email, password });
};

export const verifyEmail = async ({ verificationCode }) => {
  return await executeAPI('/api/users/verify', { verificationCode });
};

export const checkSession = async () => {
  return await executeAPI('/api/users/checkSession');
};

export const checkTenant = async () => {
  return await executeAPI('/api/users/checkTenant');
};


// var domain = /:\/\/([^\/]+)/.exec(window.location.href)[1];
// domain.split('.')
// console.log(domain.split('.'));
