import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "src/routes/hooks";
import { getPlanQuery } from "./graphql/getPlan";
import { updatePlanMutation } from "./graphql/updatePlan";
import { getGoalsQuery } from "./graphql/getGoals";
import { error } from "jquery";
import { useForm, VIEW_ACTION } from "src/components/hooks/useForm";
import { goalSchema } from "@pbhapp/common/validations/tenantValidations";
import { useSnackbarUtils } from "src/components/snackbar/snackBarUtils";
import { useFormik } from "formik";
import { PDForm } from "src/components/PDForm";
import {
  Grid,
  Typography,
  Slider,
  Divider,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { PDTextField } from "src/components/PDTextField";
import { PDErrorLabel } from "src/components/PDErrorLabel";
import { getPlansQuery } from "./graphql/getPlans";

export const Plan = () => {
  const { id } = useParams();
  const { showErrorMessage, showSuccessMessage } = useSnackbarUtils();

  const formContext = useForm({ allowActions: true, defaultMode: VIEW_ACTION });

  const { data, isLoading: getPlanLoading, error } = useQuery(getPlanQuery, {
    variables: {
      id,
    },
  });

  const [updatePlan, { loading: updatePlanLoading }] = useMutation(updatePlanMutation, {
    onCompleted: () => {
      showSuccessMessage("Plan details updated successfully");
    },
    onError: () => {
      showErrorMessage("Something went wrong, please try again");
    },
    refetchQueries: [
      getPlansQuery, // DocumentNode object parsed with gql
      "getPlans", // Query name
    ],
  });

  const { getPlan: plan } = data ?? {};

  const { name, description } = plan ?? {};

  const planForm = useFormik({
    initialValues: {
      name: name ?? "",
      description: description ?? "",
    },
    enableReinitialize: true,
    validationSchema: goalSchema,
    onSubmit: async (values) => {
      await updatePlan({
        variables: {
          id,
          name: values.name,
          description: values.description,
        },
      });
    },
  });

  const loading = getPlanLoading || updatePlanLoading;

  if (error) {
    showErrorMessage("Something went wrong loading the plan, please try again");
  }

  const { readOnly } = formContext;

  return (
    <PDForm
      label="Update Plan"
      showActionButtons={true}
      {...formContext}
      form={planForm}
      handleSave={planForm.handleSubmit}
    >
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Name</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            fullWidth
            name="name"
            autoComplete="name"
            value={planForm.values.name}
            onChange={planForm.handleChange}
            autoFocus
            disabled={readOnly}
            variant="standard"
            sx={{ width: "400px" }}
          />
          <PDErrorLabel form={planForm} field={"name"} />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="subtitle1">Description</Typography>
          <PDTextField
            margin="dense"
            size="xlarge"
            multiline
            minRows={6}
            fullWidth
            disabled={readOnly}
            name="description"
            value={planForm.values.description}
            onChange={planForm.handleChange}
            autoFocus
            variant="outlined"
            placeholder="Describe the plan"
            sx={{ width: "750px" }}
          />
          <PDErrorLabel form={planForm} field={"description"} />
        </Grid>
      </Grid>
    </PDForm>
  );
};
