/* eslint-disable */
import * as React from 'react';
import {
  Hero,
} from './homeComponents';
import { useParams } from 'react-router-dom';
import { useSnackbarUtils } from './components/snackbar/snackBarUtils';

export const Home = () => {
  const { hint } = useParams();

  const { showSuccessMessage } = useSnackbarUtils();

  if ( hint === "loggedOut" ) {
    showSuccessMessage('You have been successfully logged out');
  }

  return (
    <Hero />
  );
};
