import { useState, useCallback, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { checkSession } from "src/api/auth";
import userTypes from "@pbhapp/common/enums/userType";
import * as SessionStatus from "@pbhapp/common/enums/sessionStatus";

export const useSessionManagementQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["checkSession"],
    queryFn: checkSession,
    staleTime: 5000,
  });

  const [session, setSession] = useState({
    sessionStatus: SessionStatus.NOT_STARTED,
    user: {},
  });

  const verifySession = useCallback(
    (result) => {
      const { sessionStatus, user } = result ?? {};

      setSession((oldSession) => ({
        ...oldSession,
        sessionStatus,
        user: {
          ...(oldSession?.user ?? {}),
          ...(user ?? {}),
        },
      }));
    },
    [setSession]
  );

  const startUserSession = useCallback(
    (result) => {
      const { user, sessionStatus } = result;

      setSession((oldSession) => ({
        ...oldSession,
        sessionStatus,
        user: {
          ...(oldSession?.user ?? {}),
          ...user,
        },
      }));
    },
    [setSession]
  );

  const endUserSession = useCallback(() => {
    setSession((s) => ({
      isLoggedIn: false,
      tenant: { ...(s?.tenant ?? {}) },
    }));
  }, [setSession]);

  useMemo(() => {
    if (data) {
      const { success, sessionStatus, user } = data;

      if (success) {
        setSession((oldSession) => ({
          ...oldSession,
          sessionStatus,
          user: { ...(oldSession.user ?? {}), ...user },
        }));
      }
    }
  }, [data]);

  const { sessionStatus, user } = session ?? {};

  const { userId, userType, accessType } = user ?? {};

  const isLoggedIn = sessionStatus != SessionStatus.NOT_STARTED && !!userId;
  const isTenantAdmin = isLoggedIn && userType == userTypes.TENANT_ADMIN;

  return {
    ...session,
    isLoggedIn,
    isSessionVerified: isLoggedIn && sessionStatus === SessionStatus.VERIFIED,
    isTenantAdmin,
    isTenantUser: isLoggedIn && !isTenantAdmin,
    startUserSession,
    verifySession,
    endUserSession,
    checkingUser: isLoading,
    setSession,
    user,
  };
};
