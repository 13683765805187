import React from 'react';
import {
  CircularProgress,
  Stack,
  Typography,
  Button,
} from '@mui/material';
import * as PropTypes from 'prop-types';


export const PDLoadingButton = ({
  label,
  loading,
  sx = {},
  ...buttonProps
}) => {
  const { color = "primary" } = buttonProps ?? {};
  return (
    <Button disabled={loading} color={color} {...buttonProps} sx={{...sx}}>
      <Stack alignItems="center" direction="row" spacing={2}>
        <Typography>{label}</Typography>
        {loading && (
          <CircularProgress
            data-testid="loadingButton-Progress"
            sx={{ color: 'white' }}
            size={20}
          />
        )}
      </Stack>
    </Button>
  );
};

PDLoadingButton.prototype = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  buttonProps: PropTypes.object,
}