import { gql } from "@apollo/client";

export const createGoalMutation = gql`
  mutation creatGoal(
    $name: String!
    $description: String!
  ) {
    createGoal(
      name: $name
      description: $description
    ) {
      id
      name
      description
    }
  }
`;
