import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

// SETUP COLORS

export const black = {
  100: alpha('#000000', 100),
  60: alpha('#000000', 0.6),
  38: alpha('#000000', 0.38),
}

export const grey = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

export const primary = {
  lighter: '#0F63FE',
  light: '#009AFF',
  main: '#0F63FE',
  dark: '#293CDF',
  darker: '#0F63FE',
  contrastText: '#FFFFFF',
};

export const secondary = {
  lighter: '#DDF3FF',
  light: '#DDF3FF',
  main: '#00A9F3',
  dark: '#0069B7',
  darker: '#0069B7',
  contrastText: '#FFFFFF',
};

export const info = {
  lighter: '#CAFDF5',
  light: '#61F3F3',
  main: '##007ACB',
  dark: '#0099EE',
  darker: '#0099EE',
  contrastText: '#FFFFFF',
};

export const success = {
  lighter: '#0FFD5B',
  light: '#0FFD5B',
  main: '#0DD04B',
  dark: '#0BC145',
  darker: '#0BC145',
  contrastText: '#ffffff',
};

export const warning = {
  lighter: '#FD5C12',
  light: '#FD5C12',
  main: '#D04B0D',
  dark: '#C1450B',
  darker: '#C1450B',
  contrastText: grey[800],
};

export const error = {
  lighter: '#FD3E80',
  light: '#FD3E80',
  main: '#DF0F58',
  dark: '#D00D51',
  darker: '#7A0916',
  contrastText: '#FFFFFF',
};

export const common = {
  black: '#000000',
  white: '#FFFFFF',
};

export const action = {
  hover: alpha(common.black, 0.04),
  selected: alpha(common.black, 0.08),
  disabled: alpha(common.black, 0.38),
  disabledBackground: alpha(common.black, 0.12),
  focus: alpha(common.black, 0.12),
  hoverOpacity: 0.08,
  disabledOpacity: 0.48,
};

const base = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  grey,
  common,
  divider: alpha(common.black, 0.12),
  action,
};

// ----------------------------------------------------------------------

export function palette(mode) {
  const light = {
    ...base,
    mode: 'light',
    text: {
      primary: black[100],
      secondary: black[60],
      disabled: black[38],
    },
    alternate: {
      primary: common.white,
    },
    background: {
      paper: '#FFFFFF',
      default: '#FFFFFF',
      neutral: grey[200],
    },
    action: {
      ...base.action,
      active: grey[600],
    },
  };

  const dark = {
    ...base,
    mode: 'dark',
    text: {
      primary: '#FFFFFF',
      secondary: grey[500],
      disabled: grey[600],
    },
    alternate: {
      primary: common.black,
    },
    background: {
      paper: grey[800],
      default: grey[900],
      neutral: alpha(grey[500], 0.12),
    },
    action: {
      ...base.action,
      active: grey[500],
    },
  };

  return mode === 'light' ? light : dark;
}
