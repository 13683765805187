import axios from 'axios';
import { translate } from '../common/helper';
import urljoin from 'url-join';

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT;

const transformErrors = (errors) => {
  const result = {};

  errors.forEach((d) => {
    if (!result[d.propertyPath]) {
      result[d.propertyPath] = [];
    }

    result[d.propertyPath].push(translate(d.message));
  });

  return result;
};

const extractError = (err) => {
  if (err?.response?.status === 400) {
    const {
      response: {
        data: {
          errors: { body },
        },
      },
    } = err;
    const errors = transformErrors(body);
    return errors;
  }
};

export const executeAPI = async (route, req) => {
  try {
    const result = await axios.post(getEndpoint(route), req, {
      withCredentials: true,
    });
    const {
      data,
      data: { success, message },
    } = result;

    const { redirect, redirectTo } = message ?? {};

    if (redirect === true) {
      window.location.href = redirectTo;
    }

    return { ...data, success, message, errors: {} };
  } catch (err) {
    const errors = extractError(err);

    return { success: false, errors };
  }
};

export const executeAPIGet = async (route, req) => {
  try {
    const result = await axios.get(getEndpoint(route), req);
    const {
      data,
      data: { success, message },
    } = result;

    const { redirect, redirectTo } = message ?? {};

    if (redirect === true) {
      window.location.href = redirectTo;
    }

    return { ...data, success, message, errors: {} };
  } catch (err) {
    const errors = extractError(err);

    return { success: false, errors };
  }
};

const getEndpoint = (route) => {
  if (!apiEndpoint) {
    return route;
  }

  return urljoin(apiEndpoint, route);
}