import React, { Fragment } from "react";

import { Routes, Route, useNavigate } from "react-router-dom";

import { TenantHomePage } from "./TenantHomePage";
import { Goals } from "./Goals";
import { Plans } from "./Plans";
import { TenantPlaceholder } from "./TenantPlaceholder";
import { NewGoal } from "./NewGoal";
import { Goal } from "./Goal";
import { NewPlan } from "./NewPlan";
import { Plan } from "./Plan";


export const TenantRoutes = () => {

  return (
    <Routes element={<TenantPlaceholder />}>
      <Route index element={<TenantHomePage />} />
      <Route path="goals">
        <Route index element={<Goals />} />
        <Route path="new" element={<NewGoal />} />
        <Route path=":id" element={<Goal />} />
      </Route>
      <Route path="plans">
        <Route index element={<Plans />} />
        <Route path="new" element={<NewPlan />} />
        <Route path=":id" element={<Plan />} />
      </Route>
    </Routes>
  );
};
