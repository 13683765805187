import {
  Box,
  Grid,
  Typography,
  Card,
  CardHeader,
  CardContent,
  Button,
  Stack,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { getGoalsQuery } from "./graphql/getGoals";
import { useQuery } from "@apollo/client";
import { PDLoadingStatus } from "src/common/PDLoadingStatus";
export const Goals = () => {
  const { data, isLoading, refresh } = useQuery(getGoalsQuery);
  const navigate = useNavigate();

  if (isLoading) {
    return <PDLoadingStatus />;
  }

  return (
    <Box sx={{ pt: 5 }}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Box
            sx={{
              fontWeight: 700,
              borderBottom: "solid 1px #000",
            }}
          >
            <Typography variant="h5">Organization Goals</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              navigate("/tenant/goals/new");
            }}
          >
            Create Goal
          </Button>
        </Grid>
        <Grid item xs={12}>
          {(data?.getGoals ?? []).length === 0 && !isLoading && (
            <Typography variant="body">
              <i>No goals found</i>
            </Typography>
          )}
        </Grid>
        {(data?.getGoals ?? []).map(({ id, name, description }) => (
          <Grid item xs={12}>
            <Card key={id}>
              <CardHeader title={name} />
              <CardContent>
                <Stack direction="column" spacing={2}>
                  <Box>
                    <Typography variant="body1">{description}</Typography>
                  </Box>
                  <Link to={`/tenant/goals/${id}`}>Edit</Link>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
