const { validationConstants } = require("./constants");
const Yup = require("yup");
const tenantStatus = require("../enums/tenantStatus");
const subscriptionType = require("../enums/subscriptionType");
const dataTypes = require("../enums/dataTypes");
const { organizationTypes, employeeCounts, industries, organizationCultureOptions, organizationStructureOptions, organizationMaturityOptions, organizationFinancesOptions } = require("../constants/data");
const { minValue, maxValue } = require("../utility/formUtils");

const goalSchemaValidations = {
  name: Yup.string(validationConstants.invalidType)
    .required(validationConstants.required)
    .max(256, validationConstants.maxLength),
  
  description: Yup.string(validationConstants.invalidType)
    .required(validationConstants.required)
    .max(1000, validationConstants.maxLength),

}

const tenantDetailsValidations = {
  tenantName: Yup.string(validationConstants.invalidType)
    .required(validationConstants.required)
    .max(256, validationConstants.maxLength),
  
  organizationType: Yup.string(validationConstants.invalidType)
    .required(validationConstants.required)
    .oneOf(organizationTypes),
  
  employeeCount: Yup.string(validationConstants.invalidType)
  .required(validationConstants.required)
  .oneOf(employeeCounts),
  
  industry: Yup.string(validationConstants.invalidType)
  .required(validationConstants.required)
  .oneOf(industries),
  
  market: Yup.string(validationConstants.invalidType)
  .required(validationConstants.required)
  .max(500, validationConstants.maxLength),
  
  organizationStructure: Yup.number(validationConstants.invalidType)
  .required(validationConstants.required)
  .min(minValue(organizationStructureOptions), validationConstants.minLength)
  .max(maxValue(organizationStructureOptions), validationConstants.maxLength),
  
  organizationCulture: Yup.number(validationConstants.invalidType)
  .required(validationConstants.required)
  .min(minValue(organizationCultureOptions), validationConstants.minLength)
  .max(maxValue(organizationCultureOptions), validationConstants.maxLength),
  
  organizationMaturity: Yup.number(validationConstants.invalidType)
  .required(validationConstants.required)
  .min(minValue(organizationMaturityOptions), validationConstants.minLength)
  .max(maxValue(organizationMaturityOptions), validationConstants.maxLength),
  
  organizationFinances: Yup.number(validationConstants.invalidType)
  .required(validationConstants.required)
  .min(minValue(organizationFinancesOptions), validationConstants.minLength)
  .max(maxValue(organizationFinancesOptions), validationConstants.maxLength),

  organizationStory: Yup.string(validationConstants.invalidType)
  .max(5000, validationConstants.maxLength),
};

const clientPropertyValidator = Yup.object().shape({
  displayOnGrid: Yup.boolean(validationConstants.invalid).required(
    validationConstants.required
  ),
  propertyName: Yup.string(validationConstants.invalid)
    .matches(/^[a-zA-Z][a-zA-Z_0-9]{5,20}$/)
    .required(validationConstants.required),
  description: Yup.string(validationConstants.invalid).max(
    512,
    validationConstants.maxLength
  ),
  propertyType: Yup.number(validationConstants.invalidType)
    .oneOf([...Object.values(dataTypes)])
    .required(validationConstants.required),
});

const clientPropertiesValidator = Yup.array().of(clientPropertyValidator);

const tenantValidations = {
  tenantStatus: Yup.number(validationConstants.invalidType)
    .oneOf([...Object.values(tenantStatus)])
    .required(),
  subscriptionType: Yup.number(validationConstants.invalidType)
    .oneOf([...Object.values(subscriptionType)])
    .required(),
  domain: Yup.string(validationConstants.invalidType)
    .matches(/^[a-z\-]{5,15}$/)
    .required(validationConstants.required),
  email: Yup.string(validationConstants.invalidType)
    .email(validationConstants.invalidType)
    .required(validationConstants.required)
    .max(256, validationConstants.maxLength),
  ...tenantDetailsValidations,
};

const tenantIdValidations = {
  tenantId: Yup.string(validationConstants.invalidType).required(),
};

const tenantDetailsSchema = Yup.object().shape({
  ...tenantDetailsValidations,
});

const goalSchema = Yup.object().shape({
  ...goalSchemaValidations,
});

const planSchema = Yup.object().shape({
  ...goalSchemaValidations,
});

const createTenantValidator = Yup.object().shape({ ...tenantValidations });

const updateTenantValidator = Yup.object().shape({
  ...tenantValidations,
  ...tenantIdValidations,
});

const deleteTenantValidator = Yup.object().shape({ ...tenantIdValidations });

const validateClientProperties = async (properties = []) => {
  if (!properties || !Array.isArray(properties) || !properties.length) {
    return { isValid: true, errors: {} };
  }

  const errors = {};

  for (let i = 0; i < properties.length; i++) {
    var property = properties[i];
    await clientPropertyValidator
      .validate(property, { abortEarly: false })
      .catch((err) => {
        if (err?.inner?.length) {
          err?.inner?.forEach((element) => {
            if (element?.errors?.length) {
              const d = { [`${element.path}`]: element.errors[0] };
              errors[property.id] = { ...errors[property.id], ...d };
            }
          });
        }
      });
  }

  console.log(errors);

  return { isValid: !Object.keys(errors).length, errors };
};

module.exports = {
  createTenantValidator,
  tenantDetailsSchema,
  tenantDetailsValidations,
  deleteTenantValidator,
  updateTenantValidator,
  clientPropertiesValidator,
  clientPropertyValidator,
  validateClientProperties,
  goalSchema,
  planSchema
};
