import { useState, useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { checkTenant } from "src/api/auth";
import tenantTypes from "@pbhapp/common/enums/tenantType";

export const useTenantQuery = () => {
  const { data, isLoading } = useQuery({
    queryKey: ["checkTenant"],
    queryFn: checkTenant,
    staleTime: Infinity,
  });

  const [tenant, setTenant] = useState({
    tenantId: null,
    tenantName: null,
    domain: null,
    logo: null,
    tenantType: null,
  });

  const { tenantId, tenantName, domain, logo, tenantType } = tenant ?? {};

  useMemo(() => {
    if (data) {
      const { success, tenantId, tenantName, domain, logo, tenantType } = data;

      if (success) {
        setTenant({
          tenantId,
          tenantName,
          domain,
          logo,
          tenantType,
        });
      }
    }
  }, [data]);

  return {
    tenantId,
    tenantName,
    domain,
    logo,
    tenantType,
    isAdminTenant: tenantType === tenantTypes.ADMIN,
    isUnderTenantSpecificDomain: tenantId && !!domain,
    checkingTenant: isLoading
  };
};
