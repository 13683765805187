import React from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { AppRouter } from "./AppRouter";
import { SettingsProvider } from "./settings";
import { ThemeProvider } from "./theme";
import { ConfirmProvider } from "material-ui-confirm";

export const App = () => {
  return (
    <SettingsProvider
      defaultSettings={{
        themeMode: "light", // 'light' | 'dark'
        themeDirection: "ltr", //  'rtl' | 'ltr'
        themeContrast: "default", // 'default' | 'bold'
        themeLayout: "vertical", // 'vertical' | 'horizontal' | 'mini'
        themeColorPresets: "default", // 'default' | 'cyan' | 'purple' | 'blue' | 'orange' | 'red'
        themeStretch: false,
      }}
    >
      <ConfirmProvider>
        <ThemeProvider>
          <Router>
            <AppRouter />
          </Router>
        </ThemeProvider>
      </ConfirmProvider>
    </SettingsProvider>
  );
};
